"use client";

import { MenuElement, MenuElementProps } from "src/components/MenuElement";
import { usePathname } from "next/navigation";
import { icons } from "src/components/SiteIcons";
import { localizedText } from "src/language/LocalizedTextData";
import { useVerificationUserContext } from "src/verification/VerificationUserContext";
import { getLocale } from "src/helpers/languageHelpers";
import { signOut } from "next-auth/react";

interface ButtonElementProps extends MenuElementProps {
  link: string;
}

export function MenuButtonElement(props: ButtonElementProps) {
  const { link, ...rest } = props;

  const path = usePathname();

  if (!path) throw new Error("Menu - pathname not found!");

  const isCurrentButton = link === path;

  return (
    <MenuElement {...rest} style={isCurrentButton ? "danger" : "default"} />
  );
}

interface UserMenuButtonElementProps extends MenuElementProps {
  link: string;
}

export function UserMenuButtonElement(props: UserMenuButtonElementProps) {
  const { link, ...rest } = props;

  const { sms, email, kycStatus } = useVerificationUserContext();

  const path = usePathname();

  if (!path) throw new Error(link + " - path missing!");

  const isCurrentButton = link === path;

  const identityVerified =
    sms !== null && email !== null && kycStatus === "VERIFIED";

  return identityVerified ? (
    <MenuElement {...rest} style={isCurrentButton ? "danger" : "default"} />
  ) : (
    <VerificationElement isCurrentButton={isCurrentButton} />
  );
}

function VerificationElement({
  isCurrentButton,
}: {
  isCurrentButton: boolean;
}) {
  const locale = getLocale();

  return (
    <MenuElement
      leftIcon={icons.verify}
      rightIcon={icons.blackArrow}
      title={localizedText.menu.verify[locale]}
      style={isCurrentButton ? "danger" : "info"}
    />
  );
}

export function LogOutMenuElement(props: MenuElementProps) {
  return (
    <button onClick={() => signOut({ callbackUrl: "/auth/signin" })}>
      <MenuElement {...props} />
    </button>
  );
}
 