"use client";

export function HamburgerMenuButton(props: {
  open: boolean;
  setOpen: (value: boolean) => void;
}) {
  const { open, setOpen } = props;
  return (
    <button
      id="sidebar-button"
      onClick={() => setOpen(!open)}
      style={open ? { zIndex: 20 } : { zIndex: 0 }}
      className="group relative flex flex-col gap-1 lg:hidden"
    >
      {open ? (
        <div className="z-50 flex flex-col items-center justify-center gap-1">
          <div className="h-[0.2rem] w-5 rounded-[1px] bg-primary transition-colors duration-300 ease-in-out" />
          <div className="h-[0.2rem] w-5 rounded-[1px] bg-primary transition-colors duration-300 ease-in-out" />
          <div className="h-[0.2rem] w-5 rounded-[1px] bg-primary transition-colors duration-300 ease-in-out" />
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center gap-1">
          <div className="h-[0.2rem] w-5 rounded-[1px] bg-white transition-colors duration-300 ease-in-out group-hover:bg-primary" />
          <div className="h-[0.2rem] w-5 rounded-[1px] bg-white transition-colors duration-300 ease-in-out group-hover:bg-primary" />
          <div className="h-[0.2rem] w-5 rounded-[1px] bg-white transition-colors duration-300 ease-in-out group-hover:bg-primary" />
        </div>
      )}
    </button>
  );
}
