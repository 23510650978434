import Image from "next/image";
import { IconsLeaf } from "src/components/SiteIcons";
import { cn } from "./utils";

interface IconProps extends IconsLeaf {
  grayscale?: boolean;
}

export function Icon({
  alt,
  width,
  height,
  path,
  grayscale = true,
}: IconProps) {
  return (
    <Image
      className={cn({
        grayscale: grayscale,
      })}
      alt={alt}
      width={width}
      height={height}
      src={path}
    />
  );
}
