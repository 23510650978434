"use client";

import { icons } from "src/components/SiteIcons";
import { MenuElementProps } from "src/components/MenuElement";
import {
  LogOutMenuElement,
  MenuButtonElement,
  UserMenuButtonElement,
} from "./ButtonElements";
import Link from "next/link";
import { NavLink } from "../types/NavLink";

type MenuProps = {
  navLinks: NavLink[];
};

export function Menu(props: MenuProps) {
  const { navLinks } = props;

  return (
    <aside className="mt-24 hidden w-56 flex-col lg:flex">
      {navLinks.map((navLink, index) => {
        return navLink.verificationLink ? (
          <UserMenuButton
            key={index}
            link={navLink.link}
            size={"menu"}
            title={navLink.title}
            leftIcon={icons.user}
          />
        ) : (
          <MenuButton
            key={index}
            link={navLink.link}
            size={"menu"}
            title={navLink.title}
            leftIcon={navLink.icon}
          />
        );
      })}
      <LogOutMenuElement
        size={"menu"}
        title={"Log out"}
        leftIcon={icons.dashboard}
      />
    </aside>
  );
}

interface MenuButtonProps extends MenuElementProps {
  link: string;
}

function MenuButton(props: MenuButtonProps) {
  return (
    <Link href={props.link}>
      <MenuButtonElement {...props} />
    </Link>
  );
}

function UserMenuButton(props: MenuButtonProps) {
  const { link, ...rest } = props;

  return (
    <Link href={link}>
      <UserMenuButtonElement {...rest} link={link} />
    </Link>
  );
}
