"use client";
import { usePathname } from "next/navigation";
import { NavLink } from "../types/NavLink";
import Image from "next/image";
import { LogOutButton } from "src/user/components/LogOutButton";

type SidebarProps = {
  open: boolean;
  setOpen: (value: boolean) => void;
  navLinks: NavLink[];
};

export function SidebarMenu(props: SidebarProps) {
  const { open, setOpen, navLinks } = props;

  const path = usePathname();

  return (
    <div
      id="sideBar"
      style={{
        width: open ? "100%" : "0%",
      }}
      className="fixed top-0 right-0 z-10 h-full w-0 overflow-x-hidden bg-transparent duration-300 lg:hidden"
    >
      <div
        id="sideNav"
        style={{
          width: open ? "100%" : "0%",
        }}
        onClick={() => setOpen(false)}
        className="fixed top-0 right-0 z-40 flex h-full w-0 items-center justify-start overflow-x-hidden bg-background font-bold shadow-lg duration-300"
      >
        <div className="flex w-fit flex-row rounded-md bg-background p-1">
          <div id="dropdown-menu" className="flex flex-col 2xl:hidden">
            <div className="absolute left-10 top-40 flex flex-col items-start gap-6">
              {navLinks.map((navLink, index) => {
                return (
                  <a
                    key={index}
                    className={
                      "relative flex flex-row items-center gap-3 font-sans text-lg font-normal hover:underline " +
                      (navLink.link === path
                        ? "text-black underline"
                        : "text-primary")
                    }
                    href={navLink.link}
                  >
                    <Image
                      alt={navLink.icon.alt}
                      width={navLink.icon.width}
                      height={navLink.icon.height}
                      src={navLink.icon.path}
                    />
                    {navLink.title}
                  </a>
                );
              })}
              <LogOutButton />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
