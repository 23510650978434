"use client";

import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { getVerificationStatus } from "./actions";
import type { VerificationStatus } from "./VerificationService";
import type { KycStatus } from "shared/build/src/services/sumsub/SumsubModel";

const VerificationTimerContext = createContext<{
  timer: React.MutableRefObject<NodeJS.Timer | undefined>;
}>({
  timer: undefined as any,
});

const VerificationUserContext = createContext<{
  data: VerificationStatus;
  setData: Dispatch<SetStateAction<VerificationStatus>>;
}>({
  data: {
    sms: null,
    email: null,
    kycStatus: "NOT_STARTED",
  },
  setData: () => {},
});

export const VerificationUserProvider = ({
  children,
  sms,
  email,
  kycStatus,
}: {
  children: React.ReactNode;
  sms: Date | null;
  email: Date | null;
  kycStatus: KycStatus;
}) => {
  const timer = useRef<NodeJS.Timer | undefined>(undefined);
  const [data, setData] = useState<VerificationStatus>({
    email,
    sms,
    kycStatus,
  });

  useEffect(() => {
    setData({ email, sms, kycStatus });
  }, [email, sms, kycStatus]);

  return (
    <VerificationTimerContext.Provider
      value={{
        timer,
      }}
    >
      <VerificationUserContext.Provider
        value={{
          data,
          setData,
        }}
      >
        {children}
      </VerificationUserContext.Provider>
    </VerificationTimerContext.Provider>
  );
};

export const useVerificationUserContext = (forceInvalidate = false) => {
  const { timer } = useContext(VerificationTimerContext);
  const { data, setData } = useContext(VerificationUserContext);

  // Does not immediately refetch, but after a few second.
  // I think it's good-enough for now.
  useEffect(() => {
    if (
      data.sms === null ||
      data.email === null ||
      data.kycStatus !== "VERIFIED"
    ) {
      if (forceInvalidate) {
        clearTimeout(timer.current);
        timer.current = undefined;
      }
      if (!timer.current) {
        const newTimer = setInterval(async () => {
          const { sms, email, kycStatus } = await getVerificationStatus();

          if (sms && email && kycStatus === "VERIFIED") {
            clearInterval(newTimer);
          }

          setData({
            sms,
            email,
            kycStatus,
          });
        }, 5000);
        timer.current = newTimer;
        return () => {
          clearInterval(newTimer);
          if (timer.current === newTimer) {
            timer.current = undefined;
          }
        };
      }
    }
  }, [forceInvalidate]);
  return {
    sms: data.sms,
    email: data.email,
    kycStatus: data.kycStatus,
  };
};

export function useInvalidateUserVerificationContext() {
  const [invalidation, setInvalidationStatus] = useState(false);
  useVerificationUserContext(invalidation);

  useEffect(() => {
    if (invalidation) {
      setInvalidationStatus(false);
    }
  }, [invalidation]);

  return () => {
    setInvalidationStatus(true);
  };
}
