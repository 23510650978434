"use client";
import { signOut } from "next-auth/react";
import { SecondaryButton } from "src/components/SecondaryButton";

export function LogOutButton(props: JSX.IntrinsicElements["button"]) {
  return (
    <SecondaryButton
      {...props}
      onClick={() => signOut({ callbackUrl: "/auth/signin" })}
    />
  );
}
