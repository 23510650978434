"use client";

import { useState } from "react";
import Image from "next/image";
import { HamburgerMenuButton } from "src/menu/components/HamburgerMenuButton";
import { SidebarMenu } from "src/menu/components/SidebarMenu";
import { NavLink } from "src/menu/types/NavLink";

/* eslint react/no-unescaped-entities: 0 */
type HeaderProps = {
  navLinks: NavLink[];
};

export default function Header(props: HeaderProps) {
  const { navLinks } = props;

  const [sideBarOpen, setSideBarOpen] = useState(false);

  return (
    <div className="flex h-fit w-full flex-row justify-center bg-black py-10 px-5">
      <div className="flex w-full flex-row items-center justify-between gap-8 md:max-w-[46.5rem] lg:max-w-5xl">
        <a href={"https://k33.com"}>
          <Image
            alt="K33 logo"
            width={116 * 0.8}
            height={35}
            src={"/images/logo/K33_Logo.svg"}
          />
        </a>
        <HamburgerMenuButton
          open={sideBarOpen}
          setOpen={(value: boolean) => setSideBarOpen(value)}
        />
      </div>
      <SidebarMenu
        open={sideBarOpen}
        setOpen={(value: boolean) => setSideBarOpen(value)}
        navLinks={navLinks}
      />
    </div>
  );
}
