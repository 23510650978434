import Image from "next/image";
import { IconsLeaf } from "src/components/SiteIcons";
import { cn } from "./utils";
import { Icon } from "./Icon";

type Style = "default" | "inactive" | "danger" | "info";
type Size = "default" | "menu" | "large";

export interface MenuElementProps {
  title: string;
  text?: string;
  leftIcon: IconsLeaf;
  rightIcon?: IconsLeaf;

  style?: Style;
  size?: Size;
  active?: boolean;
}

const sizes: Record<
  Size,
  {
    height: string;
    width: string;
    rounded: string;
    padding: string;
  }
> = {
  default: {
    height: "h-[50px]",
    width: "w-[220px]",
    rounded: "rounded-[4px]",
    padding: "p-[10px]",
  },
  menu: {
    height: "h-[50px]",
    width: "w-full",
    rounded: "rounded-[4px]",
    padding: "p-[10px]",
  },
  large: {
    height: "h-[70px]",
    width: "max-w-full",
    rounded: "rounded-[5px]",
    padding: "p-[17px]",
  },
};

const styles: Record<
  Style,
  {
    textColor: string;
    backgroundColor: string;
    circleBackgroundColor: string;
  }
> = {
  default: {
    textColor: "text-[#000000B2]",
    backgroundColor: "bg-background hover:bg-menu-hover",
    circleBackgroundColor: "bg-transparent",
  },
  info: {
    textColor: "text-white",
    backgroundColor: "bg-primary",
    circleBackgroundColor: "bg-white",
  },
  inactive: {
    textColor: "text-text",
    backgroundColor: "bg-transparent",
    circleBackgroundColor: "bg-transparent",
  },
  danger: {
    textColor: "text-black",
    backgroundColor: "bg-menu-button",
    circleBackgroundColor: "bg-danger-icon",
  },
};

export function MenuElement({
  title,
  text,
  rightIcon,
  leftIcon,
  style = "default",
  size = "default",
  active = false,
}: MenuElementProps) {
  const currentStyles = styles[style];
  const currentSize = sizes[size];

  const { textColor, backgroundColor, circleBackgroundColor } = currentStyles;

  const { height, width, rounded, padding } = currentSize;

  const withCircleBg = size === "large";

  return (
    <div
      id="content-container"
      className={cn(
        "flex flex-row items-center justify-between",
        height,
        width,
        rounded,
        backgroundColor,
        padding
      )}
    >
      <div
        id="text-and-icon-container"
        className="flex flex-row items-center gap-2"
      >
        <div
          id="left-icon-container"
          className={cn(
            "flex h-[44px] w-[44px] items-center rounded-full p-[10px]",
            { [circleBackgroundColor]: withCircleBg }
          )}
        >
          <Icon {...leftIcon} />
        </div>
        <div id="text-container" className="flex flex-col">
          <h3 className={cn("text-base font-semibold", textColor)}>{title}</h3>
          <p className={cn("text-base", textColor)}>{text} </p>
        </div>
      </div>
      <div id="right-icon-container" className={cn({ "rotate-90": active })}>
        {rightIcon && (
          <Icon
            {...rightIcon}
            width={rightIcon.width / 2}
            height={rightIcon.height / 2}
          />
        )}
      </div>
    </div>
  );
}
